import { Injectable } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from '@app/app-routing-reuse-strategy';
import {
  StorageKey,
  WORKSPACE_SELECT_TIMEOUT,
  WORKSPACE_SWITCH_RETRIES,
  WORKSPACE_SWITCH_TIMEOUT,
} from '@configs';
import { LanguageServerService } from '@core/services/language-server.service';
import { ServerStateSocketService } from '@core/services/server-state-socket.service';
import { getServerErrorMessage } from '@features/auth/login/services/api-error-handler';
import { ServiceName, Workspace } from '@models';
import { Store } from '@ngrx/store';
import { DomainModelSelectors } from '@store/domain-models';
import { AppActions } from '@store/index';
import { AuthSelectors } from '@store/selectors';
import * as WorkspaceActions from '@store/workspace/actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { firstNotNullAndComplete, mapToVoid } from '@utils/operators';
import {
  Observable,
  combineLatest,
  combineLatestWith,
  concat,
  filter,
  first,
  retry,
  skip,
  take,
  takeUntil,
  tap,
  timeout,
} from 'rxjs';
import { ComponentStateService } from '../component-state.service';
import { LocalStorageService } from '../local-storage.service';
import { WorkspaceApiService } from './workspace-api.service';
import { fromWorkspaceDto } from './workspace.mapper';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  constructor(
    private _store: Store,
    private _wsApi: WorkspaceApiService,
    private _serverStateSocketService: ServerStateSocketService,
    private _languageServerService: LanguageServerService,
    private _routeReuse: RouteReuseStrategy,
    private _cmpState: ComponentStateService,
    private _localStorage: LocalStorageService
  ) {}

  private _loggedOut$ = this._store
    .select(AuthSelectors.isLoggedIn)
    .pipe(filter(isLoggedIn => !isLoggedIn));

  load(workspaceName: string): void {
    this.clearState();
    this.close();
    this._store.dispatch(WorkspaceActions.switchWorkspace());

    concat(
      this._loadWorkspaceData(workspaceName),
      this._waitForSocketsToClose(),
      this._startPanelStateService(),
      this._waitForRosettaServiceSuccess(),
      this._startLanguageService(),
      this._connectPanelSocket()
    )
      .pipe(
        skip(5),
        take(1),
        timeout(WORKSPACE_SWITCH_TIMEOUT),
        retry(WORKSPACE_SWITCH_RETRIES),
        takeUntil(this._loggedOut$)
      )
      .subscribe({
        next: () => {
          this._store.dispatch(WorkspaceActions.switchWorkspaceSuccess());
        },
        error: e => this._errorHandler(e),
      });
  }

  close(): void {
    // TODO: This will need refactoring, setting an observable
    // "isReady" for the editor to wait for is not ideal
    this._serverStateSocketService.close();
    this._languageServerService.closeSocket();
  }

  clearState(): void {
    this._store
      .select(WorkspaceSelectors.selectFirstLoad)
      .pipe(
        take(1),
        filter(isFirstLoad => !isFirstLoad)
      )
      .subscribe(() => {
        (this._routeReuse as CustomRouteReuseStrategy).clearCache();
        this._cmpState.removeAll();
        this._localStorage.clear({
          condition: key => key.startsWith(StorageKey.workspacePrefix),
        });
      });
  }

  private _errorHandler(error: any): void {
    if (error && error.status === 401) {
      // 401 are handled by the token refresh interceptor
      return;
    }
    this._store.dispatch(WorkspaceActions.switchWorkspaceFailure());
    this._store.dispatch(
      AppActions.disconnectFromWorkspace({
        errorMessage: getServerErrorMessage(error),
      })
    );
  }

  private _loadWorkspaceData(workspaceName: string): Observable<Workspace> {
    return this._wsApi.getWorkspace(workspaceName, true).pipe(
      combineLatestWith(
        this._store.select(DomainModelSelectors.selectAll).pipe(take(1))
      ),
      fromWorkspaceDto(),
      tap(workspace =>
        this._store.dispatch(WorkspaceActions.loadWorkspaceItems({ workspace }))
      ),
      take(1),
      timeout(WORKSPACE_SELECT_TIMEOUT)
    );
  }

  private _waitForSocketsToClose(): Observable<void> {
    return combineLatest([
      this._languageServerService.isSocketState(WebSocket.CLOSED),
      this._serverStateSocketService.isSocketState(WebSocket.CLOSED),
    ]).pipe(
      first(
        ([languageSocketState, panelState]) => languageSocketState && panelState
      ),
      mapToVoid()
    );
  }

  private _startPanelStateService(): Observable<void> {
    return this._store.select(WorkspaceSelectors.selectWorkspaceInfo).pipe(
      firstNotNullAndComplete(),
      tap(info => this._serverStateSocketService.start(info)),
      mapToVoid()
    );
  }

  private _waitForRosettaServiceSuccess(): Observable<void> {
    return this._serverStateSocketService
      .getServiceState(ServiceName.Rosetta)
      .pipe(
        first(state => !!state?.success),
        mapToVoid()
      );
  }

  private _startLanguageService(): Observable<void> {
    return this._store.select(WorkspaceSelectors.selectWorkspace).pipe(
      firstNotNullAndComplete(),
      tap(workspace => this._languageServerService.connectToServer(workspace)),
      mapToVoid()
    );
  }

  private _connectPanelSocket(): Observable<void> {
    return this._serverStateSocketService.isSocketState(WebSocket.OPEN).pipe(
      first(isOpen => isOpen),
      mapToVoid()
    );
  }
}
