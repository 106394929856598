import { Inject, Injectable, Optional } from '@angular/core';
import { CoreDialogService } from '@app/dialogs/core-dialog.service';
import { CODE_EDITOR_SAVE_INTERVAL } from '@configs';
import { RollbarService } from '@core/modules/rollbar/rollbar.module';
import { WorkspaceApiService, WorkspaceService } from '@core/services';
import { dialogResultOperator } from '@core/services/base-defer-dialog.service';
import { LanguageServerService } from '@core/services/language-server.service';
import { Task } from '@models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppActions, AuthActions, TaskActions } from '@store/.';
import { DomainModelSelectors } from '@store/domain-models';
import * as RouterActions from '@store/router/router.actions';
import { domainModelToWorkspaceMapper } from '@store/workspace/reducers/workspace.reducers.helper';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { catchErrorAndReturn } from '@utils/operators';
import Rollbar from 'rollbar';
import {
  Observable,
  asyncScheduler,
  catchError,
  combineLatestWith,
  concatMap,
  debounceTime,
  defer,
  filter,
  from,
  iif,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
  toArray,
} from 'rxjs';
import * as WorkspaceActions from '../actions';
import { createWorkspaceMenuItems } from './workspace.effects.helper';

@Injectable()
export class WorkspaceEffects {
  constructor(
    private _actions$: Actions,
    private _languageService: LanguageServerService,
    private _workspaceService: WorkspaceService,
    private _api: WorkspaceApiService,
    private _dialogService: CoreDialogService,
    private _store: Store,
    @Inject(RollbarService) @Optional() private _rollbar?: Rollbar
  ) {}

  loadLastWorkspace$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.loadLastWorkspace),
      switchMap(() =>
        this._api.getLastUsed().pipe(
          map(info => {
            if (!info) {
              throw new Error();
            }
            return info;
          }),
          mergeMap(info => [
            RouterActions.gotoWorkspace({ workspaceId: info.id }),
            WorkspaceActions.loadLastWorkspaceSuccess(),
          ]),
          catchErrorAndReturn(WorkspaceActions.loadLastWorkspaceFailure())
        )
      )
    );
  });

  saveWorkspace$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.saveWorkspace),
      concatMap(() =>
        this._store.select(WorkspaceSelectors.selectWorkspaceItemsToSave).pipe(
          take(1),
          concatMap(items => from(items)),
          mergeMap(item => this._api.saveWorkspaceItem(item)),
          toArray(),
          switchMap(workspaceItems =>
            this._store.select(DomainModelSelectors.selectAll).pipe(
              take(1),
              map(domainModels =>
                WorkspaceActions.saveWorkspaceSuccess({
                  workspaceItems,
                  domainModels,
                })
              )
            )
          ),
          catchErrorAndReturn(WorkspaceActions.saveWorkspaceFailure())
        )
      )
    );
  });

  saveWorkspaceItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.saveWorkspaceItem),
      concatMap(({ uri }) =>
        this._store
          .select(WorkspaceSelectors.selectWorkspaceItemFromUri(uri))
          .pipe(
            take(1),
            switchMap(item => this._api.saveWorkspaceItem(item)),
            combineLatestWith(
              this._store.select(DomainModelSelectors.selectAll).pipe(take(1))
            ),
            map(([workspaceItem, domainModels]) =>
              WorkspaceActions.saveWorkspaceSuccess({
                workspaceItems: [workspaceItem],
                domainModels,
              })
            ),
            catchErrorAndReturn(WorkspaceActions.saveWorkspaceFailure())
          )
      )
    );
  });

  saveWorkspaceFailure$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.saveWorkspaceFailure),
      map(() =>
        AppActions.showBasicErrorMsg({
          message: 'We are unable to save your changes.', // TODO: Better error message
        })
      )
    );
  });

  confirmForkFile$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(WorkspaceActions.confirmForkFile),
        switchMap(() =>
          this._dialogService
            .openConfirmation({
              title: 'Override Namespace',
              message: `This namespace is part of a parent model.<br>Unlocking will override the namespace.`,
              confirmButtonText: 'Unlock',
              confirmButtonIcon: 'lock-open',
            })
            .pipe(
              dialogResultOperator(),
              tap(() => this._languageService.addOverrideSyntax())
            )
        )
      );
    },
    {
      dispatch: false,
    }
  );

  revertWorkspaceItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.revertWorkspaceItem),
      switchMap(({ uri, name }) =>
        this._dialogService
          .openConfirmation({
            title: 'Discard Changes',
            message: `Discard changes made to <strong>${name}</strong> content?`,
            confirmButtonIcon: 'arrow-rotate-left',
            confirmButtonText: 'Discard',
          })
          .pipe(
            dialogResultOperator(),
            map(() =>
              WorkspaceActions.revertWorkspaceItemConfirmed({
                uri,
              })
            )
          )
      )
    );
  });

  revertWorkspaceItemConfirmed$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(WorkspaceActions.revertWorkspaceItemConfirmed),
        switchMap(({ uri }) =>
          this._store
            .select(WorkspaceSelectors.getWorkspaceItemByUri(uri))
            .pipe(
              take(1),
              tap(
                item =>
                  item &&
                  this._languageService.updateModelContents(uri, item.contents)
              )
            )
        )
      );
    },
    {
      dispatch: false,
    }
  );

  updateWorkspaceItemContent$ = createEffect(
    () =>
      ({
        debounce = CODE_EDITOR_SAVE_INTERVAL,
        scheduler = asyncScheduler,
      } = {}) => {
        return this._actions$.pipe(
          ofType(WorkspaceActions.updateWorkspaceItemContent),
          debounceTime(debounce, scheduler),
          map(() => WorkspaceActions.saveWorkspace())
        );
      }
  );

  saveWorkspaceThenCloseWorkspace$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.saveWorkspaceThenCloseWorkspace),
      mergeMap(({ unsetLastWorkspace, saveBeforeClose }) => [
        ...(saveBeforeClose ? [WorkspaceActions.saveWorkspace()] : []),
        WorkspaceActions.closeWorkspace({ unsetLastWorkspace }),
      ])
    );
  });

  closeWorkspace$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.closeWorkspace),
      tap(() => this._workspaceService.close()),
      mergeMap(({ unsetLastWorkspace }) =>
        iif(
          () => unsetLastWorkspace,
          defer(() => this._api.setLastUsed()),
          of(true)
        )
      ),
      mergeMap(() => [
        WorkspaceActions.clearServices(),
        WorkspaceActions.closeWorkspaceSuccess(),
      ])
    );
  });

  clearService$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(WorkspaceActions.clearServices),
        tap(() => this._workspaceService.clearState())
      );
    },
    { dispatch: false }
  );

  loadProjects$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.loadProjects),
      switchMap(() => this._loadProjects$())
    );
  });

  refreshProjectsCache$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.refreshProjectsCache),
      switchMap(() => this._loadProjects$({ refreshCache: true }))
    );
  });

  loadWorkspaces$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.loadWorkspaces),
      switchMap(() =>
        this._api
          .getWorkspaceMenuItem()
          .pipe(
            map(workspaceMenuItems =>
              WorkspaceActions.loadWorkspacesSuccess({ workspaceMenuItems })
            )
          )
      )
    );
  });

  deleteWorkspace$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.deleteWorkspace),
      concatMap(({ workspaceId }) =>
        this._api.deleteWorkspace(workspaceId).pipe(
          map(() => WorkspaceActions.deleteWorkspaceSuccess({ workspaceId })),
          catchError(() =>
            of(WorkspaceActions.deleteWorkspaceFailure({ workspaceId }))
          )
        )
      )
    );
  });

  viewAcceptedModelDocuments$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(WorkspaceActions.viewAcceptedModelDocuments),
        switchMap(({ modelId }) =>
          this._store
            .select(
              DomainModelSelectors.getModelDocumentDialogData([modelId, true])
            )
            .pipe(
              take(1),
              filter(data => !!data?.documents?.length),
              switchMap(modelDocumentData =>
                this._dialogService.openProjectLicense({
                  modelDocumentData,
                  disableClose: false,
                  readOnly: true,
                })
              )
            )
        )
      );
    },
    { dispatch: false }
  );

  createNewWorkspace$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.createNewWorkspace),
      concatMap(({ request }) =>
        this._api.createWorkspace(request).pipe(
          switchMap(workspace =>
            this._store
              .select(
                DomainModelSelectors.selectDomainModel(request.domainModelId)
              )
              .pipe(
                take(1),
                map(model => createWorkspaceMenuItems(workspace.info, model)),
                map(workspaceMenuItem =>
                  WorkspaceActions.createNewWorkspaceSuccess({
                    workspaceMenuItem,
                  })
                )
              )
          ),
          catchError(error =>
            of(WorkspaceActions.createNewWorkspaceFailure({ error }))
          )
        )
      )
    );
  });

  createNewWorkspaceSuccess$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.createNewWorkspaceSuccess),
      map(({ workspaceMenuItem }) => workspaceMenuItem.id),
      tap(() => this._dialogService.closeAll()),
      mergeMap(workspaceId => [
        RouterActions.gotoWorkspace({ workspaceId }),
        WorkspaceActions.loadWorkspaces(),
      ])
    );
  });

  createNewWorkspaceFailure$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.createNewWorkspaceFailure),
      tap(() => this._dialogService.closeAll()),
      map(({ error }) =>
        AppActions.showBasicErrorMsg({
          message: 'Server error: ' + error.error.message,
        })
      )
    );
  });

  workspaceAction$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(WorkspaceActions.workspaceAction),
        switchMap(({ action }) => {
          switch (action) {
            case 'contribute':
              return this._dialogService.openContribute();
            case 'download':
              return this._dialogService.openDownloadWorkspace();
            case 'upgrade':
              return this._dialogService.openUpgradeWorkspace();
            case 'namespace':
              return this._dialogService.openCreateNamespace();
            default:
              return undefined;
          }
        })
      );
    },
    { dispatch: false }
  );

  workspaceActionContribute$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.workspaceAction),
      filter(({ action }) => action === 'contribute'),
      map(() => WorkspaceActions.selectFirstWorkspaceItemWithChanges())
    );
  });

  gotoWorkspaces$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(
        WorkspaceActions.navigateToWorkspaces,
        WorkspaceActions.loadLastWorkspaceFailure
      ),
      map(() => RouterActions.gotoWorkspaceManager())
    );
  });

  workspaceExistsError$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.workspaceExistsError),
      mergeMap(({ workspaceName }) => [
        AppActions.showBasicErrorMsg({
          message: `Unable to open workspace "${workspaceName}".`,
        }),
        WorkspaceActions.navigateToWorkspaces(),
      ])
    );
  });

  websocketError$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.websocketError),
      tap(action => {
        if (action.rollbarError) {
          this._rollbar?.error(action.rollbarError);
        }
      }),
      map(() => WorkspaceActions.navigateToWorkspaces())
    );
  });

  invalidCompiledPojoError$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.invalidCompiledPojoError),
      switchMap(() =>
        this._store.select(WorkspaceSelectors.canRecompilePojo).pipe(take(1))
      ),
      filter(canRecompilePojo => !!canRecompilePojo),
      concatMap(() => [
        TaskActions.submitTask({
          data: {
            task: Task.PojoCompilation,
            payload: null,
          },
        }),
        WorkspaceActions.recompileInvalidPojo(),
      ])
    );
  });

  cleanupSessionState$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.cleanupSessionState),
      map(() => WorkspaceActions.cleanupSessionState())
    );
  });

  private _loadProjects$ = (
    { refreshCache }: { refreshCache: boolean } = { refreshCache: false }
  ): Observable<any> =>
    this._api.getModels(refreshCache).pipe(
      map(models => {
        const modelItems = domainModelToWorkspaceMapper(models);
        return WorkspaceActions.loadProjectsSuccess({ modelItems });
      })
    );
}
